<template>
	<!-- 发布动态 -->
	<div class="padding-top-10 pub-contain">
		<top :bgWhite='true'></top>

		<div class="content  padding-bottom-20 ">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/center' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>发布动态</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="bg-white margin-top-20 padding-lr-50">
				<div class="  padding-top-20 fs24 solid-bottom padding-bottom-15">
					动态填写
				</div>
				<div class="  padding-top-20 fs24  padding-bottom-15">
					上传内容
				</div>
				<div class="flex align-center justify-center   height420">
					<quill-editor ref="text" v-model="form.content" class="myQuillEditor w100"
						:options="editorOption" />
				</div>
				<div class="  margin-top-60 fs24  padding-bottom-15">
					上传封面
				</div>
				<el-upload class="avatar-uploader" :action="BaseUrl+'/api/v1/5fc615f7da92b'"
					:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="form.imageUrl" :src="form.imageUrl" class="width156 height126">
					<img v-else src="../../assets/image/center/add_img.png" class="width156 height126">
				</el-upload>

				<div class=" margin-top-70 padding-bottom-60 flex align-center justify-center">
					<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
						@click="submitForm()"> 保存</span>
				</div>
			</div>


		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'publishDynac',
		components: {
			bottom,
			top,
			quillEditor
		},
		data() {
			return {
				content: '', //富文本内容
				editorOption: {},
				imageUrl: '', //封面
				form: {
					title: '', //标题
					content: '', //文章内容
					is_my: true,
					imageUrl: '', //封面
					artileType: '', //文章类型
					is_first: true, //是否首发
					is_pay: true, //是否付费
				}
			}
			// editorOption里是放图片上传配置参数用的，例如：
			// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
			// methods: 'post',  // 必填参数 图片上传方式
			// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
			// name: 'upload_file',  // 必填参数 文件的参数名
			// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
			// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
		},
		mounted() {

		},
		methods: {
			// 上传成功回调
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			// 上传封面
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt10M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isLt10M;
			},
			submitForm() {
				this.$router.push({
					path: '/memberDate'
				})
				console.log(this.$refs.text.value)
			},
		}
	}
</script>

<style scoped>
	.pub-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		padding-right: 30px;
	}

	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.el-form-item {
		display: flex;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
</style>
